import React from 'react';
import styled from "@emotion/styled"
import ResponsiveImage from "../../components/commons/responsive-image"
import CardTitle from '../../components/commons/card-title';

export default function ProductCard({post}) {
  const {bgcolor, title, price, url, image} = post

  return(
    <a href={url} target="_blank" rel="noreferrer">
      <ProductContainer bgColor={bgcolor}>
        <ResponsiveImage image={image} />
        <div>
          <CardTitle color="white">{title}</CardTitle>
          <ProductPrice>R$ {price}</ProductPrice>
        </div>
      </ProductContainer>
    </a>
  )
}

const ProductContainer = styled.article`
  background-color: ${(props) => props.bgColor};
  border-radius: 20px;
  height: 100%;
  padding: 0 1em 1.5em;

  img {
    display: block;
    height: auto;
    margin: 0 auto;
    width: 274px;
  }
`

const ProductPrice = styled.span`
  color: white;
  display: block;
  font-size: 1.125rem;
  font-weight: 500;
  margin-top: .3em;
`
