import React from "react"
import styled from "@emotion/styled"
import { Link } from "gatsby"
/** @jsx jsx */
import { css,  jsx } from '@emotion/react'
import ResponsiveImage from "./responsive-image"
import CardTitle from "./card-title"

function InspirationCard(data) {
  const { featuredImage, slug, title, link } = data.post
  return (
    <Link to={link}>
      <article>
        <ImgContainer isHome={data.isHome}>
          <Figure>
            <ResponsiveImage
              image={featuredImage.node}
              style={css`
                display: block;
                height: 100%;
                object-fit: cover;
                width: 100%;
              `}
            />
          </Figure>
          <TitleContainer>
            <CardTitle color="white">{title}</CardTitle>
          </TitleContainer>
        </ImgContainer>
      </article>
    </Link>
  )
}

export default InspirationCard

const ImgContainer = styled.div`
  height: 0;
  margin: 0;
  overflow: hidden;
  padding-bottom: ${({ isHome }) => isHome ? '138.888888889%' : '83.3328%'};
  position: relative;
  width: 100%;
`

const Figure = styled.figure`
  border-radius: 10px;
  height: 100%;
  margin: 0;
  overflow: hidden;
  position: absolute;
  width: 100%;
  z-index: 1;
`

const TitleContainer = styled.div`
  background: linear-gradient(to top, rgba(0,0,0,.6) 0%,transparent 45%);
  border-radius: 10px;
  color: #ffffff;
  display: flex;
  align-items: flex-end;
  padding: 0 30% 30px 20px;
  font-size: 3.125rem;
  font-weight: normal;
  height: 100%;
  left: 0;
  margin: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
`
