import homeRecipes from '../../assets/img/home-recipes-bg.png'
import homeInspiration from '../../assets/img/home-inspirations-bg.png'
import homeProducts from '../../assets/img/home-products-bg.png'

const GetBackground = ( section ) => {
  const Images = {
    recipes: homeRecipes,
    inspirations: homeInspiration,
    products: homeProducts,
  }

  return Images[section] || ''
}

export default GetBackground