import React from "react"
import styled from "@emotion/styled"
/** @jsx jsx */
import { css,  jsx } from '@emotion/react'
import GetBackground from "./GetBackground"
import Container from "../../components/commons/container"
import { Link } from "gatsby"
import { Subtitle } from "../../components/commons/styled-components"
import { roundButtonStyles } from "../../components/commons/round-button-styles"

const HomeSection = ({ options, children }) => {
  const { bgColor, postType, sectionTitle, titleColor, button } = options
  const { btnText, color, bkg, link, externalLink } = button

  //console.log(linkToList)

  return (
    <SectionContainer BgColor={bgColor} postType={postType}>
      <Container
        css={css`
          padding: 25px 0;
          @media (min-width: 991px) {
            margin: 0 auto;
            padding: 48px 0 40px;
          }
        `}
      >
        <SectionTitle color={titleColor}>{sectionTitle}</SectionTitle>

        {children}

        <ButtonContainer>
          {
            externalLink ? (
              <a href={link} target="_blank" rel="noreferrer" css={css`&:hover {filter: none;}`}>
                <RoundedLink colors={{ color: color, bkg: bkg, hoverColor: bgColor }}>
                  {btnText}
                </RoundedLink>
              </a>
            ) : (
              <Link to={link} css={css`&:hover {filter: none;}`}>
                <RoundedLink colors={{ color: color, bkg: bkg, hoverColor: bgColor }}>
                  {btnText}
                </RoundedLink>
              </Link>
            )
          }
          
        </ButtonContainer>
      </Container>
    </SectionContainer>
  )
}

export default HomeSection

const SectionContainer = styled.section`
  background-color: ${(props) => props.BgColor};
  background-image: url(${(props) => GetBackground(props.postType)});
  background-position: center top;
  background-repeat: no-repeat;
  background-size: 100%;
  padding: 0 24px;
  width: 100%;

  @media (min-width: 991px) {
    padding: 0;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 30px;
`

const SectionTitle = styled(Subtitle)`
  margin-top: 0;
`

const RoundedLink = styled.span`
  ${roundButtonStyles}
`
