import React from 'react';
import styled from "@emotion/styled"

const CardTitle = (props) => <Title {...props}>{props.children}</Title>
export default CardTitle

const Title = styled.h3`
  font-weight: normal;
  font-size: 3.125rem;
  line-height: 3.125rem;
  margin: 0;
  text-transform: uppercase;
  color: ${(props) => props.color};
`