import React from "react"
import Img from "gatsby-image"
import styled from "@emotion/styled"
import { css } from '@emotion/react'
import { use100vh } from 'react-div-100vh'

function FeaturedImage({ featuredImage }) {
  const height = use100vh()
  const fullHeight = height ? height + 'px' : '100vh'

  if (!!featuredImage?.node?.localFile?.childImageSharp?.fluid) {
    return (
      <ImageContainer css={css`
          height: ${fullHeight};
          min-height: ${fullHeight};
        `}>
        <StyledGatsbyImage
          alt={featuredImage.altText}
          fluid={featuredImage.node.localFile.childImageSharp.fluid}
        />
      </ImageContainer>
    )
  } else if (!!featuredImage?.node?.srcSet) {
    return (
      <ImageContainer css={css`
          height: ${fullHeight};
          min-height: ${fullHeight};
        `} >
        <StyledImage
          alt={featuredImage.altText}
          srcSet={featuredImage.node.srcSet}
        />
      </ImageContainer>
    )
  } else {
    return null
  }
}

export default FeaturedImage

const ImageContainer = styled.div`
  margin-top: -45px;
  width: 100vw;
  z-index: 99;
  height: 90vh;

  @media (min-width: 991px) {
    width: 100%;
    height: 100%;
    margin-top: 0;
  }

  .gatsby-image-wrapper img {
    transition: opacity 0.6s ease 1.5s !important;
  }

  .gatsby-image-wrapper picture img {
    opacity: 0;
    transition: opacity 0.6s ease 1s !important;
  }

  &:before {
    background: transparent
      linear-gradient(
        to bottom,
        hsla(0, 0%, 0%, 0.35) 0%,
        hsla(0, 0%, 0%, 0.346) 8.1%,
        hsla(0, 0%, 0%, 0.333) 15.5%,
        hsla(0, 0%, 0%, 0.314) 22.5%,
        hsla(0, 0%, 0%, 0.289) 29%,
        hsla(0, 0%, 0%, 0.259) 35.3%,
        hsla(0, 0%, 0%, 0.227) 41.2%,
        hsla(0, 0%, 0%, 0.192) 47.1%,
        hsla(0, 0%, 0%, 0.158) 52.9%,
        hsla(0, 0%, 0%, 0.123) 58.8%,
        hsla(0, 0%, 0%, 0.091) 64.7%,
        hsla(0, 0%, 0%, 0.061) 71%,
        hsla(0, 0%, 0%, 0.036) 77.5%,
        hsla(0, 0%, 0%, 0.017) 84.5%,
        hsla(0, 0%, 0%, 0.004) 91.9%,
        hsla(0, 0%, 0%, 0) 100%
      )
      0% 0% no-repeat padding-box;
    content: "";
    height: 80px;
    left: 0;
    pointer-events: none;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 9;

    @media (min-width: 991px) {
      display: none;
    }
  }

  &:after {
    background: transparent linear-gradient(0deg, #00000065 0%, #0000 100%) 0%
      0% no-repeat padding-box;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;

    @media (min-width: 991px) {
      display: none;
    }
  }
`

const StyledGatsbyImage = styled(Img)`
  display: block;
  height: 100%;
  width: 100%;
  object-fit: cover;
`

const StyledImage = styled.img`
  display: block;
  height: 100%;
  width: 100%;
  object-fit: cover;
`
