import React from "react"
import { Article } from "../../components/commons/styled-components"
import FeaturedItem from "./featured-item"
import Layout from "../../components/layout"
import RecipesSection from "./recipes-section"
import InspirationsSection from "./inspirations-section"
import ProductsSection from "./products-section"

const Frontpage = ({ data }) => {
  const { page, lastRecipes, lastInspirations } = data
  const { acfFrontpage } = page

  const { item } = acfFrontpage

  return (
    <Layout seo={page.seo}>
      <Article>
        {item && <FeaturedItem acf={acfFrontpage} />}

        {/* RECIPES LIST */}
        <RecipesSection data={lastRecipes} />

        {/* INSPIRATION LIST */}
        <InspirationsSection data={lastInspirations} />

        {/* Products LIST */}
        <ProductsSection data={acfFrontpage} />
      </Article>
    </Layout>
  )
}

export default Frontpage
