import React from "react"
import { graphql } from "gatsby"
import Frontpage from "./frontpage"

const MainFrontPage = ({ data }) => <Frontpage data={data} />
export default MainFrontPage

export const query = graphql`
  query frontpage($id: String!) {
    allPosts: allWpPost {
      nodes {
        title
        slug
      }
    }
    page: wpPage(id: { eq: $id }) {
      title
      content
      seo {
        canonical
        title
        opengraphDescription
        opengraphImage {
          sourceUrl
        }
      }
      acfFrontpage {
        item {
          ... on WpPost {
            id
            nodeType
            title
            slug
            featuredImage {
              node {
                localFile {
                  ...HeroImage
                }
              }
            }
          }
          ... on WpInspiration {
            id
            nodeType
            title
            slug
            link
            featuredImage {
              node {
                localFile {
                  ...HeroImage
                }
              }
            }
          }
          ... on WpPage {
            id
            nodeType
            title
            slug
            featuredImage {
              node {
                localFile {
                  ...HeroImage
                }
              }
            }
          }
        }
        products {
          bgcolor
          title
          price
          url
          image {
            srcSet
          }
        }
        lead
        cta
        shapes
      }
    }

    lastRecipes: allWpPost(sort: { fields: dateGmt, order: DESC }, limit: 6) {
      edges {
        node {
          title
          slug
          featuredImage {
            node {
              altText
              srcSet
            }
          }
          acfRecipe {
            lead
            difficulty
            cookingtime {
              hours
              minutes
            }
            maintag {
              name
            }
          }
        }
      }
    }

    lastInspirations: allWpInspiration(
      sort: { fields: dateGmt, order: DESC }
      limit: 2
    ) {
      edges {
        node {
          featuredImage {
            node {
              altText
              srcSet
            }
          }
          title
          slug
          link
        }
      }
    }
  }
`
