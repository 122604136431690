import React from "react"
import Card from "../../components/commons/recipe-card"
import { CardsContainer } from "../../components/commons/cards-container"
import HomeSection from "./section-container"

const RecipesSection = ({ data }) => {
  const options = {
    postType: "recipes",
    sectionTitle: "últimas receitas",
    titleColor: "#D99F4F",
    bgColor: "#E1E1D4",
    button: {
      color: "#D99F4F",
      bkg: "transparent",
      btnText: "ver mais receitas",
      externalLink: false,
      link: "/receitas",
    },
  }

  return (
    <HomeSection options={options}>
      <CardsContainer>
        {data.edges.map((post, i) => (
          <Card key={i} post={post.node} isHome />
        ))}
      </CardsContainer>
    </HomeSection>
  )
}

export default RecipesSection
