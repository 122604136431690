import React from "react"
/** @jsx jsx */
import { css,  jsx } from '@emotion/react'
import Card from "../../components/commons/inspiration-card"
import { CardsContainer } from "../../components/commons/cards-container"
import HomeSection from "./section-container"

const InspirationsSection =  ({ data }) => {
  const options = {
    postType: "inspirations",
    sectionTitle: "inspirações",
    titleColor: "#FFFFFF",
    bgColor: "#789A78",
    button: {
      color: "#FFFFFF",
      bkg: "transparent",
      btnText: "Ver outras coleções",
      externalLink: false,
      link: "/inspiracoes",
    },
  }

  return (
    <HomeSection options={options}>
      <CardsContainer
        css={css`
          grid-template-columns: repeat(1, 1fr);

          @media (min-width: 768px) {
            grid-template-columns: repeat(2, 1fr);
          }
        `}
      >
        {data.edges.map((post, i) => (
          <Card key={i} post={post.node} isHome />
        ))}
      </CardsContainer>
    </HomeSection>
  )
}

export default InspirationsSection